import { FC } from 'react'

interface Props {
  default?: boolean
  path?: string
}

const SuperAdmin: FC<Props> = (props) => {
  return null
}

export default SuperAdmin
